import request from '@/utils/request'
/**
 *添加考试
 */
export function createTest (test) {
  return request({
    url: 'bh/test/createTest',
    method: 'post',
    data: test
  })
}
/**
 *查询考试
 */
export function findTestInfo (query) {
  return request({
    url: 'bh/test/findTestInfo',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 *修改考试
 */
export function detailTest (id) {
  return request({
    url: `bh/test/detailTest?id=${id}`,
    method: 'get'
  })
}
/**
 *提交修改考试
 */
export function updateTest (test) {
  return request({
    url: 'bh/test/updateTest',
    method: 'post',
    data: test
  })
}
/**
 *录入考试试题
 */
export function addTestAndQuestionsInfo (test) {
  return request({
    url: 'bh/test/addTestAndQuestionsInfo',
    method: 'post',
    data: test
  })
}
/**
 *修改考试试题
 */
export function updataTestAndQuestionsInfo (test) {
  return request({
    url: 'bh/test/updateQuestions',
    method: 'post',
    data: test
  })
}
/**
 *删除考试信息
 */
 export function delTestByTest (testId) {
  return request({
    url: `bh/test/delTestByTestId?testId=${testId}`,
    method: 'get'
  })
}
/**
 *初始化考试信息
 */
export function findStuTestInfo (query) {
  return request({
    url: 'bh/student/test/findStuTestInfo',
    method: 'get',
    params: {
      ...query
    }
  })
}
// export function findStuTestInfo (pageNum, pageSize, testName) {
//   return request({
//     url: `bh/student/test/findStuTestInfo?pageNum=${pageNum}&pageSize=${pageSize}&testName=${testName}`,
//     method: 'get'
//   })
// }
/**
 *初始化考试信息
 */
export function detailStuTest (id) {
  return request({
    url: `bh/student/test/detailStuTest?id=${id}`,
    method: 'get'
  })
}
/**
 *学员提交考试
 */
export function addStudentTestInfo (data) {
  return request({
    url: 'bh/student/test/addStudentTestInfo',
    method: 'post',
    data: data
  })
}
/**
 *学员查看考试成绩
 */
export function showTestScore (testId, classId) {
  return request({
    url: `bh/student/test/showTestScore?testId=${testId}&classId=${classId}`,
    method: 'get'
  })
}
